import * as dompack from 'dompack';
import * as mso from '@mod-mso/webdesigns/mso/mso';
import '@mod-mso/webdesigns/mso/mso.scss';

import './bit.scss';
import './widgets/widgets';
import './pages/actions/actions';
import '@mod-bit/pages/intranet/intranet';

dompack.onDomReady(() => {
  new mso.MSOSite();
});
