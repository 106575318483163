/* global $ */
import './expandabletabs.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  $('.w-expandabletabs--bit__item__heading--expand').on('click', function () {
    let item = $(this.closest('.w-expandabletabs--bit__item'));

    // Only allows one tab to be opend at a time
    let parent = $(item.parent('.w-expandabletabs--bit--container'));
    let items = $(parent.find('.w-expandabletabs--bit__item'));

    items.each(function () {
      let object = $(this);
      let content = $(object.find('.w-expandabletabs--bit__item__content'));
      if (content.is(':visible')) {
        content.slideUp();
        changeIcon(object, 'fa-minus', 'fa-plus');
      }
    });

    // Displays and hides content of clicked item
    let content = $(item.find('.w-expandabletabs--bit__item__content'));
    if (content.is(':hidden')) {
      content.slideDown();
      item.attr('data-collapsed', 'false');
      changeIcon(item, 'fa-plus', 'fa-minus');
    } else {
      content.slideUp();
      setTimeout(() => {
        item.attr('data-collapsed', 'true');
      }, 400);
      changeIcon(item, 'fa-minus', 'fa-plus');
    }

    // Function to change icon
    function changeIcon(target, oldIconClass, newIconClass) {
      let icon = $(target.find('.' + oldIconClass));
      if (icon != undefined) {
        icon.removeClass(oldIconClass);
        icon.addClass(newIconClass);
      }
    }
  });
});
