import './intranet.scss';

import "@mod-wrd/js/auth";
import * as whintegration from '@mod-system/js/wh/integration';
import * as rpc from "./intranet.rpc.json";

// dompack
import * as dompack from 'dompack';

// let breadCrumbItems = [];
let flatFolderList = [];
console.log("mounted intranet page");

dompack.onDomReady(() => {
  // login screen
  if (document.documentElement.classList.contains('page-login')){
    dompack.qS('input[name="username"]').value = "wouter@webwerf.nl";
    dompack.qS('input[name="password"]').value = "a";
  }

  if (!document.documentElement.classList.contains('page-intranet'))
    return;

  let filemanager = dompack.qS('.filemanager');
  if (filemanager != undefined) {
    runFileManager(filemanager);
    return;
  }
});

function runFileManager(filemanager) {
  let pageHash = decodeURIComponent(location.hash).slice(1).split('=');
  if (pageHash.length > 1 && pageHash[0] === 'search')
    dompack.qS('#intranetsearch').value = pageHash[1];

  let breadcrumbs = dompack.qS('.breadcrumbs.intranet__path'),
    fileList = filemanager.querySelector('.data');

  rpc.getIntranetFSTree(whintegration.config.obj.startfolderid, whintegration.config.obj.currentfileid)
     .then((data) => {

          if (!data) {
            filemanager.querySelector('.nothingfound').style.display = "block";
            filemanager.querySelector('.search').style.display = "none";
            return;
          }

          filemanager.querySelector('.search').style.display = "none";

          flatFolderList = data.folders;

          data = data.menu;
          let response = [data],
            currentPath = '',
            breadcrumbsUrls = [];

          let folders = [],
            files = [];

          // This event listener monitors changes on the URL. We use it to
          // capture back/forward navigation in the browser.
          goto(window.location.hash);
          window.addEventListener("hashchange", () => {
            goto(window.location.hash);

            // We are triggering the event. This will execute
            // this function on page load, so that we show the correct folder:
            // let customevent = new Event("hashchange");
            // window.dispatchEvent(customevent);
          });

    // Hiding and showing the search box

    // Listening for keyboard input on the search field.
    // We are using the "input" event which detects cut and paste
    // in addition to keyboard input.

    filemanager.querySelector('input').addEventListener("input", (event) => {
      folders = [];
      files = [];

      let value = event.target.value.trim();

      if(value.length) {
        filemanager.classList.add('searching');

        // Update the hash on every key stroke
        window.location.hash = 'search=' + value.trim();

      } else {
        filemanager.classList.remove('searching');
        window.location.hash = encodeURIComponent(currentPath);
      }
    });
    // FIXME: fix search
    filemanager.querySelector('input').addEventListener("focusout", (event) => {
      // let search = $(this);

      if(!event.target.value.trim().length) {

        window.location.hash = encodeURIComponent(currentPath);
      }
    });

    // Clicking on folders
    applyFolderClick();

    // Navigates to the given hash (path)

          function goto(hash) {

            hash = decodeURIComponent(hash).slice(1).split('=');

            if (hash.length) {
              let rendered = '';

              // if hash has search in it
              if (hash[0] === 'search') {

                filemanager.addClass('searching');
                rendered = searchData(response, hash[1].toLowerCase());

                if (rendered.length) {
                  currentPath = hash[0];
                  render(rendered);
                }
                else {
                  render(rendered);
                }

              }

              // if hash is some path
              else if (hash[0].trim().length) {
                rendered = searchByPath(hash[0]);

                currentPath = hash[0];
                breadcrumbsUrls = generateBreadcrumbs(hash[0]);
                render(rendered);
              }

              // if there is no hash
              else {
                currentPath = data.path;
                breadcrumbsUrls.push(data.path);
                render(searchByPath(data.path));
              }
            }
            applyFolderClick();
          }

          // Splits a file path and turns it into clickable breadcrumbs

          function generateBreadcrumbs(nextDir){
            let path = nextDir.split('/').slice(0);
            for(let i=1;i<path.length;i++){
              path[i] = path[i-1]+ '/' +path[i];
            }
            return path;
          }


  // Locates a file by path

    function searchByPath(dir) {
      let path = dir.split('/'),
        demo = response,
        flag = 0;

      for(let i=0;i<path.length;i++){
        for(let j=0;j<demo.length;j++){
          if(demo[j].name === path[i]){
            flag = 1;
            demo = demo[j].items;
            break;
          }
        }
      }

      demo = flag ? demo : [];
      return demo;
    }


    // Recursively search through the file tree
    function applyFolderClick() {
      let lifolder = dompack.qSA("li.folders");

    lifolder.forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();

        let nextDir = element.querySelector('a.folders').getAttribute("href");

        if(filemanager.classList.contains('searching')) {

          // Building the breadcrumbs

          breadcrumbsUrls = generateBreadcrumbs(nextDir);

          filemanager.classList.remove('searching');
          filemanager.querySelector('span').style.display = "block";
        }
        else {
          breadcrumbsUrls.push(nextDir);
        }

        window.location.hash = encodeURIComponent(nextDir);
        currentPath = nextDir;
      });
    });
    }

    function searchData(data, searchTerms) {

      data.forEach(function(d){
        if(d.type === 'folder') {

          searchData(d.items,searchTerms);

          if(d.name.toLowerCase().match(searchTerms)) {
            folders.push(d);
          }
        }
        else if(d.type === 'file') {
          if(d.name.toLowerCase().match(searchTerms)) {
            files.push(d);
          }
        }
      });

      return {folders: [], files: files};
    }


          // Render the HTML for the file manager

          function render(data) {

            let scannedFolders = [],
              scannedFiles = [];

            if(Array.isArray(data)) {

              data.forEach(function (d) {

                if (d.type === 'folder') {
                  scannedFolders.push(d);
                }
                else if (d.type === 'file') {
                  scannedFiles.push(d);
                }

              });

            }
            else if(typeof data === 'object') {

              scannedFolders = data.folders;
              scannedFiles = data.files;

            }


            // Empty the old result and make the new one

            fileList.innerHTML = "";
            fileList.style.display = "none";

            if(!scannedFolders.length && !scannedFiles.length) {
              filemanager.querySelector('.nothingfound').style.display = "block";
            }
            else {
              filemanager.querySelector('.nothingfound').style.display = "none";
            }

            if(scannedFolders.length) {

              scannedFolders.forEach(function(f) {

                let itemsLength = f.items.length,
                  // name = escapeHTML(f.name),
                  icon = '<span class="icon folder"></span>';

                if(itemsLength > 0) {
                  icon = '<span class="icon folder full"></span>';
                }

                if(itemsLength == 1) {
                  itemsLength += ' onderdeel';
                }
                else if(itemsLength > 1) {
                  itemsLength += ' onderdelen';
                }
                else {
                  itemsLength = 'Lege map';
                }

                // let folder = $('<li class="folders"><a href="'+ f.path +'" title="'+ f.title +'" class="folders">'+icon+'<span class="name">' + f.title + '</span> <span class="details">' + itemsLength + '</span></a></li>');
                let folder = document.createElement("li");
                folder.classList.add("folders");
                folder.innerHTML = '<a href="'+ f.path +'" title="'+ f.title +'" class="folders">'+icon+'<span class="name">' + f.title + '</span> <span class="details">' + itemsLength + '</span></a>';
                fileList.append(folder);
                // folder.appendTo(fileList);
              });

            }

            if(scannedFiles.length) {

              scannedFiles.forEach(function(f) {

                let fileSize = bytesToSize(f.size),
                  name = escapeHTML(f.name),
                  fileType = name.split('.'),
                  icon = '<span class="icon file"></span>';

                fileType = fileType[fileType.length-1];

                icon = '<span class="icon file f-'+fileType+'">.'+fileType+'</span>';

                let file = document.createElement("li");
                file.classList.add("files");
                file.innerHTML = `<a href="${f.path}" title="${f.path}" class="files">${icon}<span class="name">${f.title}</span><span class="details">${fileSize}</span></a>`;

                fileList.append(file);
                // file.appendTo(fileList);
              });

            }

            // Generate the breadcrumbs

            let url = '';

            if(filemanager.classList.contains(('searching'))){

              url = '<span>Zoekresultaten:</span>';
              fileList.classList.remove('animated');

            }
            else {

              fileList.classList.add('animated');

              breadcrumbsUrls.forEach(function (u, i) {

                let name = u.split('/');

                const folderObj = findFolderByPath(u);
                let folderTitle = folderObj && folderObj.title ? folderObj.title : name[name.length-1];

                if (i !== breadcrumbsUrls.length - 1) {
                  url += '<a href="'+ u +'"><span class="foldername">' + folderTitle + '</span></a> <span class="arrow">→</span> ';
                }
                else {
                  url += '<span class="foldername">' + folderTitle + '</span>';
                }

              });

            }
            breadcrumbs.innerHTML = url;

            // Clicking on breadcrumbs
            let breadcrumbshrefs = breadcrumbs.querySelectorAll("a");

            breadcrumbshrefs.forEach((element) => {
              element.addEventListener('click', (e) => {
                e.preventDefault();

                let aitems = breadcrumbs.querySelectorAll("a");
                aitems.forEach((element, index) => {
                  let nextDir = breadcrumbsUrls[index];
                  breadcrumbsUrls.length = Number(index);

                  window.location.hash = encodeURIComponent(nextDir);
                });
              });
            });


            // Show the generated elements
            fileList.animate({'display':'inline-flex'});
            fileList.style.display = "flex";
          }


          // This function escapes special html characters in names

          function escapeHTML(text) {
            return text.replace(/\&/g,'&amp;').replace(/\</g,'&lt;').replace(/\>/g,'&gt;');
          }


          // Convert file sizes from bytes to human readable units

          function bytesToSize(bytes) {
            let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0B';
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
          }

          function findFolderByPath(findPath) {
            let result = {};

            flatFolderList.forEach(function(folderFromList) {
              if (folderFromList.path === findPath) {
                result = folderFromList;
                return true;
              }
            });

            return result;
          }

     })
     .catch(onFormException);
};

function onFormException(result = {}) {
  console.error(result);
}
